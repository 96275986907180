<template>
  <div class="fixedPageContainer">
    <router-link :to="'editFixedPage/' + page.key + '?title=' + page.title" class="fixedPageCell" v-for="page in state.pages">
      <IconsWorker :icon="page.icon" :width="50"/>
      <div class="pageTitle">{{page.title}}</div>
    </router-link>
  </div>
</template>

<script>
import IconsWorker from "../../components/IconsWorker";
import {reactive} from "vue";
export default {
  name: "FixedPages",
  components: {IconsWorker},
  setup() {
    const state = reactive({
      pages: [
        { icon: 'info', title: 'لمحة عنا', key: 'aboutUs' },
        { icon: 'userGard', title: 'سياسة الخصوصية', key: 'privacy'  },
        { icon: 'termsF', title: 'شروط الاستخدام', key: 'terms'  },
      ]
    });
    return {
      state,
    }
  },
}
</script>

<style scoped>
  .fixedPageContainer{
    padding: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 20px;
    row-gap: 20px;
    text-align: start;
    gap: 20px 3.3%;
  }

  .fixedPageContainer a{
    color: inherit;
  }

  .fixedPageCell{
    width: 25%;
    height: 110px;
    padding: 20px 2.5%;
    background: #eee;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    place-content: space-around;
    place-items: center;
  }

  .fixedPageCell:hover{
    background: #ccc;
  }
  .fixedPageCell:active{
    background: #999;
  }

  .fixedPageCell .pageTitle{
    font-size: 16px;
    font-weight: bold;
  }
</style>